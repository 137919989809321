'use client';

import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';

import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import Image from 'next/image';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { customLocalStorage } from '@/helpers/storage.helpers';

import { USER_ACCESS_TOKEN } from '@/constants/user-credentials.constants';

import { Button } from '@/components/Buttons';

import styles from '@/views/HomeView/HomePromocodeSection/HomePromocodeSection.module.scss';

const items: { count: string; title: string }[] = [
  {
    count: '33 000+',
    title: 'moviesAndSerials',
  },
  {
    count: '170+',
    title: 'TVChannels',
  },
  {
    count: '1 600+',
    title: 'cartoons',
  },
  {
    count: '1 200+',
    title: 'uzbVideo',
  },
];

export const HomePromocodeSection = () => {
  const t = useTranslations('page.home.promocode');
  const [currentBgOrder] = useState([1, 2, 3][Math.floor(Math.random() * 3)]);
  const { userAccessToken } = useVerification();
  const [hasNotAccessToken, setHasNotAccessToken] = useState<boolean>(true);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasNotAccessToken(Boolean(customLocalStorage.getItem(USER_ACCESS_TOKEN)));
    }
  }, [userAccessToken]);

  if (hasNotAccessToken) return null;

  return (
    <section className={styles.promocodeSection}>
      <Container className={clsx(styles.container, { [styles.dark]: currentBgOrder === 3 })}>
        <div className={styles.wrapper}>
          <Image src="/assets/images/lights.png" alt="promocode" fill className={styles.lights} />
          <Image
            src={`/assets/images/promocode-bg-${currentBgOrder}.png`}
            alt="promocode"
            width={430}
            height={349}
            className={styles.bg}
          />
          <Image
            src={`/assets/images/promocode-bg-mobile-${currentBgOrder}.png`}
            alt="promocode"
            width={430}
            height={349}
            className={styles.bgMobile}
          />
          <div className={styles.content}>
            <h3 className={styles.title}>
              <span>{t('mostPopular')}</span> {t('moviesOnCinerama')}
            </h3>
            <div className={styles.items}>
              {items.map(item => (
                <div key={item.title} className={styles.item}>
                  <div className={styles.count}>{item.count}</div>
                  <div className={styles.label}>{t(item.title)}</div>
                </div>
              ))}
            </div>
            <Button as="link" size="lg" href="/subscriptions" variant="primary-gradient">
              {t('buttonText')}
            </Button>
          </div>
        </div>
      </Container>
    </section>
  );
};
