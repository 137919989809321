'use client';

import { Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import Snowfall from 'react-snowfall';

// =================================================================

export const SnowFallWrapper = () => {
  return (
    <Fragment>
      <Snowfall style={{ zIndex: 10, position: 'fixed' }} radius={[0.5, 3]} snowflakeCount={50} />
      <Snowfall
        style={{ position: 'fixed' }}
        radius={[0.5, 4]}
        snowflakeCount={isMobile ? 50 : 100}
      />
    </Fragment>
  );
};
