'use client';

import { useEffect } from 'react';

import { useTranslations } from 'next-intl';

import { moduleTypes } from '@/app.config';
import ChevronRightSVG from '@/icons/ChevronRightSVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';
import { useHomeTvList } from '@/views/HomeView/OnlineTvList/useHomeTvList';

import { ForwardedLink } from '@/components/ForwardedLink';

import { HomeContentBaseSlider } from '@/views/HomeView/HomeContentBaseSlider';
import { OnlineTVListViewAllButton } from '@/views/HomeView/OnlineTvList/OnlineTVListViewAllButton';
import { OnlineTvCard } from '@/views/HomeView/OnlineTvList/OnlineTvCard';
import { OlineTvCardSkelton } from '@/views/HomeView/OnlineTvList/OnlineTvCardSkelton';
import styles from '@/views/HomeView/OnlineTvList/OnlineTvList.module.scss';

export const OnlineTvList = () => {
  const t = useTranslations('page.home');

  const { userAccessToken } = useVerification();

  const { refetch, channels, status } = useHomeTvList();

  useEffect(() => {
    if (userAccessToken) {
      refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccessToken]);

  return (
    <HomeContentBaseSlider
      xs={3}
      sm={3}
      md={4}
      lg={5}
      xl={6}
      xxl={7}
      heading={
        <ForwardedLink
          href={`/${moduleTypes.onlineTv}?categoryId=15`}
          className={styles.sectionHeading}
        >
          {t('onlineTv')}
          <ChevronRightSVG className={styles.icon} />
        </ForwardedLink>
      }
      className={styles.onlineTvList}
      slideClassName={styles.onlineTvListSlide}
    >
      {status === 'pending'
        ? Array(8)
            .fill(null)
            .map((_, index) => <OlineTvCardSkelton key={index} />)
        : channels.map(channel => (
            <OnlineTvCard
              key={channel.id}
              channel={channel}
              isAuthenticated={Boolean(userAccessToken)}
              onSuccessLiked={refetch}
            />
          ))}
      <OnlineTVListViewAllButton href={`/${moduleTypes.onlineTv}`} />
    </HomeContentBaseSlider>
  );
};
