import(/* webpackMode: "eager" */ "/var/www/cnnew/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/components/SnowFallWrapper/SnowFallWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/cnnew/src/views/HomeView/BannerSlider/BannerSlider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HomeCollections"] */ "/var/www/cnnew/src/views/HomeView/HomeCollections/HomeCollections.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/views/HomeView/HomeGenres/HomeGenres.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/views/HomeView/HomePromocodeSection/HomePromocodeSection.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/views/HomeView/OnlineTvList/OnlineTvList.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/views/HomeView/UnfinishedMovieList/UnfinishedMovieList.tsx");
